.tag {
    display: inline-flex;
    font-size: px-to-rem(13);
    letter-spacing: 0.29px;
    padding: spacer(2) spacer(5);
    line-height: 1.2;
    border-width: 1px;
    border-style: solid;
    border-radius: 50rem;
    font-weight: $font-weight-medium;
    font-family: $headings-font-family;
    white-space: nowrap;

    @each $name, $color in $theme-colors {
        &--#{$name} {
            color: $color;
            border-color: $color;
            
        }
    }
}

;@import "sass-embedded-legacy-load-done:952";
.project-grid {
    counter-reset: card;
    display: flex;
    flex-direction: column;

    &__item {
        order: 1;

        &.project-grid__text {
            order: 0;
        }
    }
}

.archive-toggle {
    .grid, .list {
        border: 0;
        background: transparent;
        opacity: .5;
        transition: opacity $global-duration;

        &.is-active, &:hover {
            opacity: 1;
        }
    }
}

;@import "sass-embedded-legacy-load-done:940";
@use "sass:color";

.mega-menu {
    position: absolute;
    padding: spacer(20) spacer(8);
    top: 100%;
    background-color: $quinary;
    z-index: $zindex-dropdown;
    margin: 0 auto;

    @extend .container;

    left: 0;
    right: 0;
    opacity: 0;
    visibility: hidden;
    min-height: 100%;
    pointer-events: none;
    transform: translateY(spacer(5));
    transition: opacity $global-duration ease-in, transform $global-duration ease-in, visibility $global-duration ease-in;
    display: none;

    @include media-breakpoint-up(lg) {
        display: initial;
        border-bottom-right-radius: 24px;
        border-bottom-left-radius: 24px;
    }
}

.mega-menu__submenu {
    display: flex;
    flex-direction: column;
    gap: spacer(2);
}

.menu__item--mega-menu {
    &.menu__item--level-3 {
        position: unset;
        color: rgba($primary, 0.6);
        transition: color 200ms;

        &:hover {
            color: $primary;
        }
    }

    &:hover {
        // Show direct mega menu on hover
        &>.mega-menu {
            opacity: 1;
            visibility: visible;
            pointer-events: initial;
            transform: translateY(0);
            color: $primary;
        }

        // Show direct menu overlay on hover
        &>.menu-overlay {
            opacity: 1;
        }
    }

    &.menu__item--level-2 {
        font-weight: $font-weight-bold;
        font-size: px-to-rem(18);
    }
}

// Menu cols options for mega menu
@for $i from 1 through 10 {
    .menu__col--#{$i} {
        .menu--submenu {
            @include media-breakpoint-up(lg) {
                display: flex;
                flex-wrap: wrap;
                align-items: flex-start;
            }

            // Set basis on childs of submenu
            & > * {
                @include media-breakpoint-up(lg) {
                    flex-basis: calc(100% / #{$i});
                }
            }
        }

        .card--row {
            flex-basis: 100%;
        }
    }
}

;@import "sass-embedded-legacy-load-done:965";
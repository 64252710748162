.download {
    border-radius: $border-radius;
    position: relative;
    background-color: $white;
    border-bottom-right-radius: px-to-rem(24);
}

.download__container {
    padding: spacer(10);
    display: flex;
    gap: spacer(10);
    align-items: center;

    @include media-breakpoint-up(lg) {
        flex-flow: row;
    }
}

.download__title {
    font-family: $headings-font-family;
    font-weight: $font-weight-semi-bold;
    line-height: 1.2;
    flex: 1;
}


.download__link {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: $border-radius;
    background-color: $quaternary;
    text-decoration: none;
    padding: spacer(2);
    width: fit-content;

    @include media-breakpoint-up(lg) {
        align-self: center;
        margin-left: auto;
    }
}

;@import "sass-embedded-legacy-load-done:957";
.pagination-block {
    border-radius: px-to-rem(4);
    border-bottom-left-radius: px-to-rem(24);
    
    .pagination {
        display: flex;
        align-items: center;
        padding-left: 0;
        text-decoration: none;
        list-style-type: none;
        color: $color-base;
        font-weight: $font-weight-bold;
        margin-bottom: 0;

        .prev,
        .next {
            align-items: center;
            display: flex;
        }

        a {
            color: rgba($color-base, 0.6);
            text-decoration: none;

            &:hover {
                color: $primary;
            }
        }

        li {
            &:before {
                display: none;
            }

            padding: spacer(2) spacer(3);
            margin: 0 spacer(2);
            border-radius: $border-radius;
            font-size: px-to-rem(18);

            &:first-of-type {
                margin-left: auto !important;
            }

            &:last-of-type {
                margin-right: auto !important;
            }

            &.prev {
                margin-right: auto
            }

            &.next {
                margin-left: auto;
            }

            &.current {
                color: $white;
                background: $primary;
            }


            &.btn {
                color: $secondary;

                a {
                    color: $secondary;
                    text-decoration: none;
                }

                &.disabled {
                    opacity: .6;

                    &:before {
                        display: none;
                    }

                    &:hover {
                        transition: none;
                    }
                }
            }
        }

        .first {
            display: flex;
            flex-direction: row-reverse;

            .material-icons {
                transform: scaleX(-1);
            }
        }
    }
}

;@import "sass-embedded-legacy-load-done:976";
.topbar {
  padding: spacer(4) 0;

  @include media-breakpoint-down(lg){
    display: none;
  }

  .review {
    .review__text {
      font-size: px-to-rem(14);
      font-family: $headings-font-family; 
      color: $senary;
      opacity: .6;
      transition: opacity $global-duration;
    }

    a {
      text-decoration: none;

      &:hover {
       .review__text {
          opacity: 1;
       } 
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:951";
.menu--tabs {
    display: flex;
    flex-direction: column;

    .menu__link {
        display: flex;
        font-size: px-to-rem(15);
        padding: spacer(2) 0; 
        transition: opacity .2s ease-in-out;

        /* stylelint-disable */
        &:after {
            content: "\f061";
            position: relative;
            font-family: 'Font Awesome 6 Pro';
            font-weight: 400;
            font-size: px-to-rem(12);
            left: 0;
            transition: left .2s ease-in-out;
        }
        /* stylelint-enable */

        &:hover {
            opacity: .7;

            &:after {
                left: spacer(1);
            }
        }
    }
}

;@import "sass-embedded-legacy-load-done:969";
.menu--primary {
    justify-content: center;
    padding: spacer(8);
    transition: color 300ms ease-in;
    height: 100%;
    
    // Menu item level 1
    & > .menu__item {
        display: flex;
        align-items: stretch;
        transition: background-color $global-duration ease-in;

        &:hover {
            @include media-breakpoint-up(lg){
                background-color: $quinary;
                color: initial !important;
            }
        }
    }

    &:hover {
        & > .menu__item {
            color: rgba($black, 0.3);
        }
    }

    @include media-breakpoint-up(lg){
        padding: unset;
    }


    .menu__link {
        display: flex;
        align-items: center;
        height: 100%;
        font-size: px-to-rem(15);

        @include media-breakpoint-up(lg){
            padding-top: 0;
            padding-bottom: 0;
        }
    }
}
;@import "sass-embedded-legacy-load-done:966";
.cta-link {
  padding: spacer(9) spacer(8);
  border-radius: $border-radius;
  border-bottom-left-radius: px-to-rem(24);
  background-color: $white;
  width: fit-content;
}

.cta-link__title {
  color: $secondary;
  font-size: px-to-rem(20);
  font-weight: $font-weight-semi-bold;
  line-height: 1;
}

.cta-link__content {
  font-size: px-to-rem(20);

  & > * {
    margin-bottom: unset;
    color: $color-base;
  }
}

.cta-link__inner {
  display: flex;
  gap: spacer(8);
  align-items: center;
  
  @include media-breakpoint-up(lg) {
    gap: spacer(12);
  }
}

.cta-link--hero {
  margin: 0 auto;
  display: block;
  z-index: 12;
  transform: translateY(-50%);
  margin-bottom: -40%;
  height: fit-content;

  @include media-breakpoint-up(md){
    position: absolute;
    margin-bottom: unset;
    transform: unset;
    right: 0;
    bottom: -#{spacer(8)};
  }

  @include media-breakpoint-up(lg){
    max-width: 20rem;
    margin-right: calc($grid-gutter-width * 2);
  }

  @include media-breakpoint-up(xl){
    max-width: unset;
  }
}

;@import "sass-embedded-legacy-load-done:978";
@use "sass:map";

$darken-amount: 10%;
$button-transition-duration: $global-duration * 1.2;

.btn {
    display: inline-flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    border-radius: $border-radius-sm;
    background: initial;
    line-height: 1.3;
    padding: spacer(4);
    text-decoration: none;
    color: $white;
    width: auto;
    gap: spacer(3); // min amount of space between text and icon
    z-index: 1;
    font-size: $font-size-base;
    font-weight: $font-weight-bold;
    border: none;

    &:hover {
        text-decoration: none;
        color: $white;
    }

    &--container {
        margin-top: spacer(6);

        @include media-breakpoint-up(lg) {
            margin-top: spacer(10);
        }
    }
}

// Button colors
@each $name, $map in $btn-colors {
    .btn--#{$name} {
        background: map.get($map, "background");
        color: map.get($map, "color");
        transition: background-color 250ms ease-in, color 250ms ease-in;

        // Button colors inverted
        &-inverted {
            color: map.get($map, "background");
            background: transparent;
            box-shadow: inset 0 0 0 2px map.get($map, "background");
            transition: background-color $button-transition-duration,
                color $button-transition-duration;
        }

        &-bordered {
            box-shadow: inset 0 0 0 1px map.get($map, "color");
        }

        &:hover {
            $hover-map: map.get($map, "hover");

            @if $hover-map {
                background: map.get($hover-map, "background");
                color: map.get($hover-map, "color");
            }
        }
    }
}

.btn-group {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: spacer(4);
    justify-content: flex-start;
}

// Used to show icon first, button text second
.btn--reversed {
    flex-direction: row-reverse;
}

.btn--rounded {
    border-radius: 50rem;
    font-weight: bold;
    padding: spacer(3) spacer(6);
    text-align: center;

    &:after {
        width: 0;
    }
}

/* Add this class to any button with an icon inside of it
this will take care of the icon padding when used before or after text
*/
.btn__icon {
    display: flex;
    align-items: center;

    .icon:not(:first-child){
        padding-right: 0;
    }

    .icon:not(:last-child){
        padding-left: 0;
    }
}

.btn--center {
    width: fit-content;
    margin: 0 auto;
}

.btn--video {
    position: relative;

    &:after {
        content: '\f04b';
        position: absolute;
        left: 0;
        right: 0;
        text-align: center;
        font-family: 'Font Awesome 6 Pro', sans-serif;
        font-size: 3rem;
        font-weight: $font-weight-bold;
        top: 50%;
        color: $white;
        transform: translateY(-50%);
        transition: color $global-duration;
    }

    &:hover {
        &:after {
            color: rgba($white, .8);
        }
    }
}

.btn--disabled {
    opacity: .6;
    pointer-events: none;
    cursor: default;
}

.btn-return {
    padding: spacer(2) spacer(4);
    gap: spacer(2);

    & > .btn__icon .icon {
        pointer-events: none;
    }
}

.btn--ghost {
    background: unset;
    border: unset;
    display: flex;
    align-items: center;
    gap: spacer(4);
}

.btn--header {
    @include media-breakpoint-down(lg){
        display: none;
    }

    flex-shrink: 0;

    &.is-hidden--mobile {
        @include media-breakpoint-down(lg){
            pointer-events: none;
            visibility: hidden;
            opacity: 0;
            transform: translateY(-100%);
            z-index: -1;
        }
    }
}

.btn--cta-mobile {
    position: relative;
    bottom: 0;
    width: calc(100% - #{$container-padding-x });
    justify-content: center;
    display: flex;
    margin: 0 auto;
    top: spacer(4);
}

.btn--icon {
    width: spacer(13);
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
    position: unset !important;
}

;@import "sass-embedded-legacy-load-done:941";
.video {
  width: 100%;
}

.video__container-inline {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;

  iframe {
      height: auto !important;
  }
  
  &.video__container-inline--autoplay {
       iframe {
          height: 56.25vw;
       }
  }
}
;@import "sass-embedded-legacy-load-done:948";
.cta {
    padding: $pane-spacer 0;
    background-size: cover;
    background-position: center;
    position: relative;

    @include media-breakpoint-up(md) {
        padding: #{$pane-spacer * 1.5} 0; // 60px
    }

    @include media-breakpoint-up(lg) {
        padding: #{$pane-spacer * 3} 0; // 80px;
    }

    h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
        color: inherit;
    }

    .cta__content {
        position: relative;
        z-index: 1;
        padding: spacer(10);
        background-color: $white;
        border-radius: spacer(1) spacer(1) spacer(1) spacer(6);
        transform: translateY(#{$pane-spacer * 1.5});
        
        @include media-breakpoint-up(md) {
            transform: translateY(#{$pane-spacer * 2});
        }
    
        @include media-breakpoint-up(lg) {
            transform: translateY(#{$pane-spacer * 4});
        }
    }
}

;@import "sass-embedded-legacy-load-done:977";
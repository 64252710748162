.swiper--logo {
    .swiper-wrapper {
        align-items: center;
    }

    .swiper__slide {
        .img-fluid {
            object-fit: contain;
            object-position: center;
        }
    }

    @include media-breakpoint-up(lg) {
        &[class*="swiper--destroy"] {
            .swiper-wrapper {
                display: grid;
                grid-template-columns: repeat(auto-fit, minmax(275px, 1fr));
                align-items: center;
                justify-content: center;
                gap: 60px 40px;
                grid-template-rows: 1fr 1fr;

                @include media-breakpoint-up(lg) {
                    gap: 100px 40px;
                    grid-template-rows: auto;
                }
            }
        }
    }
}

;@import "sass-embedded-legacy-load-done:960";
.form-control {
    color: $color-base;
    border-radius: spacer(1);
    background-color: $quaternary;
    border: 1px solid rgba($primary, .5);
    padding: spacer(3) spacer(5) !important;
}

input {
    &[type="text"], &[type="tel"], &[type="email"], &[type="url"] {
        @extend .form-control;
    }
}

select, textarea {
    @extend .form-control;
}

.gfield_label, label {
    font-weight: $font-weight-semi-bold !important;
    font-size: px-to-rem(14) !important;
}

.gchoice {
    display: flex;
    gap: spacer(4);
    align-items: center;

    label {
        font-weight: $font-weight-normal !important;
        font-size: px-to-rem(15) !important;
    }
}

.gform_drop_area {
    color: $color-base;
    border-radius: spacer(1);
    background-color: $quaternary;
    border: 1px solid rgba($primary, .5);
}
;@import "sass-embedded-legacy-load-done:980";
.usp {
    background: $white;
    padding: spacer(10) spacer(8);
    border-radius: spacer(1) spacer(1) spacer(1) spacer(6);
    display: flex;
    flex-direction: column;
    gap: spacer(6);
    height: 100%;

    .usp__icon {
        color: $secondary;
        font-size: px-to-rem(24);
    }

    .usp__text {
        font-size: $h5-font-size;
        font-family: $headings-font-family;
        font-weight: $headings-font-weight;
        line-height: 1.2;
    }
}

;@import "sass-embedded-legacy-load-done:956";
.pane {
    display: block;

    & + .pane--archive {
        @each $breakpoint, $pane-spacer in $pane-spacers {
            @include media-breakpoint-up($breakpoint) {
                margin-top: calc(-#{$pane-spacer} - (#{$pane-spacer} / 1.5));
            }
        }
    }
    
    @each $breakpoint, $pane-spacer in $pane-spacers {
        @include media-breakpoint-up($breakpoint) {
            padding: $pane-spacer 0;
        }
    }

    &.bg--default {
        &+ .pane.bg--default {
            padding-top: 0;
        }
    }

    // &:not(.pane--tabs) {
    //     ul {
    //     @extend .list-checked
    //     }
    // }
};

@each $name, $color in $theme-colors {
    .pane.bg--#{$name} {
        + .pane.bg--#{$name} {
            padding-top: 0;
        }
    }
}

.pane--swiper, .pane--swiper_reviews {
    overflow: hidden;
}

.pane--text, .pane--text_double {
    ul {
        @extend .list-checked;
    }
}

.pane--highlighted_products_cta {
    .column-cta {
        border-radius: spacer(1) spacer(1) spacer(1) spacer(6);
        background: $tertiary;
        color: $white;
        padding: spacer(15) spacer(12);

        @include media-breakpoint-up(lg) {
            margin-bottom: -#{spacer(15)};
        }

        ul {
            @extend .list-checked; 

            li {
                font-size: $h5-font-size;

                &:before {
                    color: $white !important;
                }
            }
        }
    }
}

.pane--two_column_text_image {
    .image__text {
        position: absolute;
        background: white;
        padding: spacer(8);
        width: 66%;
        left: 50%;
        transform: translateX(-50%);
        bottom: -#{spacer(8)};
        border-radius: spacer(1) spacer(1) spacer(1) spacer(6);
        
        p {
            color: $color-base;
            margin-bottom: 0;
        }    
    }

    .col--white {
        background-color: $white;
        position: relative;
        border-radius: 0 spacer(1) spacer(6) 0;
        padding: spacer(10);
        
        @include media-breakpoint-up(lg) {
            padding: spacer(10) spacer(20) spacer(10) spacer(15);
            
            &:before {
                content: '';
                position: absolute;
                background-color: $white;
                height: 100%;
                width: $grid-gutter-width * 0.5;
                top: 0;
                left: -#{$grid-gutter-width * 0.5};
            }
        }
    }

    .flex-row-reverse {
        .col--white {
            border-radius: spacer(1) 0 0 spacer(6);
            
            @include media-breakpoint-up(lg) {
                padding: spacer(10) spacer(15) spacer(10) spacer(20);

                &:before {
                    content: '';
                    position: absolute;
                    background-color: $white;
                    height: 100%;
                    width: $grid-gutter-width * 0.5;
                    top: 0;
                    right: -#{$grid-gutter-width * 0.5};
                    left: unset;
                }
            }
        }
    }
}

.pane--forms {
    .form {
        background: $white;
        padding: spacer(12);
        border-radius: spacer(1) spacer(1) spacer(1) spacer(6);
    }
}

.pane--comparisons {
    overflow: hidden;

    .row--white {
        padding: spacer(20) 0;
    }

    .table {
        border-collapse: separate; 
        border-spacing: spacer(5) 0;
        
        @include media-breakpoint-up(lg) {
            table-layout: fixed;
            width: 100%;
            border-spacing: spacer(10) 0;
        }

        tbody {
            td {
                padding: spacer(4) spacer(6);
                width: 100%;

                &:nth-child(1n+2) {
                    text-align: center;
                    padding: 0 spacer(6);
                    border: 1px solid rgba($color-base, 8%);
                    border-top: 0;
                    border-bottom: 0;
                }
            }
            
            tr {
                &:last-child {
                    td {
                        &:nth-child(1n+2) {
                            padding: spacer(6);                            
                            border: 1px solid rgba($color-base, 8%);
                            border-top: 0;
                            border-bottom: 1px solid rgba($color-base, 8%);
                            border-bottom-left-radius: spacer(6);
                            border-bottom-right-radius: spacer(1);
                        }
                    }
                }
            }
        }

        &.table-striped {
            tbody {
                tr {
                    border-radius: spacer(1);
                    position: relative;
                    z-index: 1;

                    &:nth-child(odd) {
                        background-color: $quaternary; /* Background color of your choice */
                    }
                }
            }
        }

        p {
            margin-bottom: 0;
        }

        th {   
            font-weight: $font-weight-normal;
            color: $color-base;

            @include media-breakpoint-up(lg) {
                width: 100%;
            }

            > p {
                color: $color-base;
            }

            &:nth-child(1n+2) {
                padding: spacer(6);
                border-top-left-radius: spacer(1);
                border-top-right-radius: spacer(1);
                border: 1px solid rgba($color-base, 8%);
                border-bottom: 0;
            }
        }
    }
}

.pane--archive_project {
    margin-top: spacer(13);
    
    @include media-breakpoint-up(lg) {
        margin-top: 0;
    }

    .filters {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .filters__widget, .filters__toggle {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            gap: spacer(4);
            align-items: center;
            
            .facetwp-facet {
                margin-bottom: 0;
            }

            @include media-breakpoint-up(lg) {
                gap: spacer(8);
                justify-content: flex-start;
            }
        }
    }
}

.pane--swiper_projects {
    position: relative;
}
;@import "sass-embedded-legacy-load-done:938";
.quicklink {
    display: flex;
    background: $white;
    border-radius: spacer(1) spacer(1) spacer(1) spacer(6);
    box-shadow: unset;
    transition: box-shadow $global-duration;
    cursor: pointer;
    justify-content: space-between;

    &--image {
        border-radius: spacer(1) spacer(1) spacer(6) spacer(1);

        img {
            display: none;
            
            @include media-breakpoint-up(md) {
                display: flex;
                border-radius: 0;
                height: 100%;
                flex: 1;
                aspect-ratio: 1/1;
                width: 148px;
                object-fit: cover;
                border-top-left-radius: spacer(1);
                border-bottom-left-radius: spacer(1);
            }
        }
    }

    &__icon {
        transition: transform $global-duration;
        color: $white;
        border-radius: spacer(1);
        
        svg {
            transform: rotate(45deg);
        }
    }

    &:hover {
        box-shadow: $box-shadow;

        .quicklink__icon {
            // transform: translateX(0.5rem);
        }
    }

    @each $name, $color in $theme-colors {
        .quicklink--#{$name}{
            background: $color;
            transition: background-color $button-transition-duration, color $button-transition-duration;

            @if $name == 'quinary' or $name == 'white' {
                color: $color-base;
            }
        }
    }
}

.quicklink-divider {
    width: 1px;
    background: $secondary;
    opacity: 0.2;
}

.quicklink--archive {
    margin: spacer(4);

    @include media-breakpoint-down(lg){
        right: 0;
    }

    @include media-breakpoint-up(md){
        width: calc(50% - #{spacer(4) * 2});
    }

    @include media-breakpoint-up(lg){
        width: calc(25% - #{spacer(4) * 2});
    }
}

.quicklink--grey {
    background: $secondary;
}

.quicklink_link {
    flex: 1;

    p {
        margin-bottom: 0;
    }
}

.quicklink__title, .quicklink__content  {
    font-size: px-to-rem(16);
    color: $color-base;
}

.quicklink__title {
    margin-bottom: 0;
}

.quicklink__content {
    margin-bottom: 0;
    font-size: px-to-rem(14);
    flex: 1;
}

.quicklink-container {
    position: relative;
    flex: 1;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    padding: spacer(5);
    gap: spacer(5);
        
    @include media-breakpoint-up(md) {
        flex: 3;
        padding: spacer(5) spacer(10);
        gap: spacer(10);
    }

    .icon {
        flex-shrink: 0;
    }
}

;@import "sass-embedded-legacy-load-done:955";
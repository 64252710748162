.breadcrumbs {
    position: absolute;
    width: 100%;
    margin-top: spacer(15);
    z-index: 10 !important;
    left: 0;
}

.breadcrumbs__nav {
    display: inline-flex;
    align-items: center;
    background: rgba($quaternary, 0.5);
    padding: spacer(1) spacer(4);
    border-radius: $border-radius-pill;
    flex-wrap: wrap;
    
    @extend .list;

    font-size: px-to-rem(14);
    font-family: $headings-font-family;
    font-weight: $font-weight-normal;
    margin: 0;

    a {
        text-decoration: none;
    }

    // @include media-breakpoint-down(md){
    //     > *:not(:nth-child(3)) {
    //         display: none;
    //     }
    // }
}

.breadcrumbs__seperator {
    margin: 0 spacer(2);
    color: $color-base;
}

.breadcrumbs__link {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    width: auto;
    flex: 1;

    &:first-child {
        flex-grow: 0;
        flex-basis: auto;
    }
}

.breadcrumb_last {
    opacity: 0.75;
    font-weight: $font-weight-light;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    width: auto;

    @include media-breakpoint-down(md){
        flex: 1;
    }
}

;@import "sass-embedded-legacy-load-done:962";
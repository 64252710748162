.menu--mobile {
    display: flex;
    flex-direction: column;
    gap: spacer(4);
    padding-top: spacer(15);
    padding-bottom: spacer(6);

    .menu__item {
        color: #272727;
    }

    .menu__item--level-2 {
        font-weight: $font-weight-bold;
        font-size: px-to-rem(20);
        border: unset;
    }
}

.menu__section {
    padding-bottom: spacer(6);

    .menu--mobile {
        padding-top: unset;
        gap: 0;
    }
}

;@import "sass-embedded-legacy-load-done:968";
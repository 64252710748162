.menu--secondary {
  gap: spacer(9);
  padding-top: 0;

  .menu__item {
    color: rgba($senary, 0.6);
    transition: color 200ms;
    font-size: px-to-rem(14);

    &:hover {
      color: $senary;
    }
  }
  
  .menu__link {
    display: flex;
  }

  @include media-breakpoint-up(lg) {
      padding: 0;

      .menu__item {
          padding: 0;
      }

      .menu__item--parent {
          &:first-of-type {
              .icon--dropdown {
                  display: none;
              }
          }
      }
  }
}

;@import "sass-embedded-legacy-load-done:967";
input[type="radio"] {
    @extend .form-check-input;

    width: spacer(4) !important;
    height: spacer(4) !important;
    padding: spacer(3) !important;
    margin: 0;
    background-color: $quaternary;

    &:checked {
        background-image: none;
        box-shadow: inset 0 0 0 5px $quaternary;
        background-color: $secondary;
        border-color: $secondary !important;
    }
}

.gfield_radio {
    display: flex;
    flex-direction: column;
    gap: spacer(3);
}

;@import "sass-embedded-legacy-load-done:984";
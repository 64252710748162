@use "sass:color";

.tabs__nav {
    @extend .list-unstyled;

    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 0;
    overflow: auto hidden;

    @include media-breakpoint-up(md){
        align-items: stretch;
        justify-content: stretch;
        margin-left: 0;
        overflow: hidden;
    }
}

.tab {
    position: relative;
    padding: spacer(3) spacer(12);
    cursor: pointer;
    background:color.adjust($quaternary, $lightness: -2%);
    min-width: fit-content;

    &.is-active {
        background: $white;
        color: $secondary;
        font-family: $headings-font-family;
        z-index: 9;
        
        @include media-breakpoint-up(lg) {
            margin-bottom: -2px;
        }
    }

    &:first-child {
        border-top-left-radius: spacer(1);
    }
    
    &:last-child {
        border-top-right-radius: spacer(1);
    }
}

@each $name, $color in $theme-colors {
    .bg--#{$name} {
        .tab.is-active {
     
        }
    }
}


.tab__content {
    transform: translateY(-10px);
    height: 0;
    opacity: 0;
    overflow: hidden;
    background: $white;
    transition: opacity 250ms ease-in, transform 250ms ease-in;
    border-radius: spacer(1) spacer(1) spacer(1) spacer(6);

    .card__image {
        aspect-ratio: 1/1;
        height: unset;
    }

    @include media-breakpoint-up(lg){
        display: flex;
        gap: spacer(4);
        flex-wrap: wrap;
    
        & > * {
            flex: 1;
            flex-basis: calc(50% - #{spacer(4)});

            &:nth-child(3n+3){
                flex-grow: 0;
            }
        }
    }

    &.is-active {
        height: auto;
        opacity: 1;
        padding: spacer(20) spacer(17);
        transform: translateY(0);
    }

    .btn--container {
        &:before {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            transform: translateY(26px) translateX(-30%);
            background: url('../../img/tab-deco.svg');
            background-repeat: no-repeat;
            background-position: right top;
            z-index: -1;
        }
    }
}

.tab__block {
    padding: spacer(6);
}


.tabs--hero, .tabs--pane {  
    .tabs__container {
        padding: 0 spacer(12) spacer(8) spacer(12);
        border-radius: 0 0 spacer(1) spacer(6);
        background: $white;
    }

    .tabs__title {
        background: $white;
        border-top-left-radius: spacer(1);
        border-top-right-radius: spacer(1);
        padding: spacer(12);
        color: $white;
        position: relative;

        p {
            color: $white;
        }

        &:before {
            content: '';
            position: absolute;
            background: url('../../img/hero-tabs-deco.svg');
            background-repeat: no-repeat;
            background-size: cover;
            background-position: bottom right;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            z-index: 0;
            border-top-left-radius: spacer(1);
            border-top-right-radius: spacer(1);
        }

        &-inner {
            position: relative;
            z-index: 1;
            margin-bottom: spacer(8);
        }
    }
    
    
    .tabs__nav {
        transform: translateY(-#{spacer(12)});
        display: flex;
        position: relative;
        z-index: 1;
        
        .tab {
            padding: spacer(3);
            text-align: center;
            width: 100%;
        }
    }

    .tab__content {
        &:before {
            display: none;
        }

        &.is-active {
            margin-top: calc(-#{spacer(12)} - 2px); /* -2px zodat ie altijd iets overlapt bij de buttons */
            padding: spacer(8) 0 0 0;
        }
    }
}


;@import "sass-embedded-legacy-load-done:974";
.project-information {
  background: $white;
  padding: spacer(10) spacer(8);
  border-radius: $border-radius;
  position: sticky;
  top: 0;
}

.project-information__title {
  color: $secondary;
  margin: 0;

  @extend .h4;
}


.single-project {
  .breadcrumbs {
    @include media-breakpoint-down(lg) {
      margin-top: spacer(25);
    }
  }
}
;@import "sass-embedded-legacy-load-done:973";
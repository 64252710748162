// Default swiper
/* stylelint-disable */
.swiper {
    &__slide {
        @extend .swiper-slide;
    }

    &--full {
        .swiper__slide  {
            border-radius: $border-radius;
            border-bottom-left-radius: px-to-rem(24);
            overflow: hidden;
    
            & > * {
                width: 100%;
            }
        }
    }

    &--logos {
        .swiper-wrapper {
            @include media-breakpoint-up(lg){
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                gap: spacer(9) 0;
            }
        }

        .swiper__slide {
            background: $white;
            padding: spacer(12);
            border-radius: $border-radius;
            border-bottom-left-radius: px-to-rem(24);
            display: flex;
            align-items: center;
            justify-content: center;
            flex-grow: 1;
            height: auto;

            & > * {
                flex: 1;
            }
        }
    }

    &__button {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 9;
        background-color: $primary;
        color: $white;
        transition: all .3s;
        border-radius: $border-radius-sm;
        display: flex;
        width: spacer(13);
        height: spacer(13);
        justify-content: center;
        align-items: center;
        aspect-ratio: 1/1;

        &:hover {
            background-color: $black;
        }

        &--next {
            right: spacer(3);
            transform: translateY(-50%);
        }

        &--prev {
            left: spacer(3);
            transform: translateY(-50%);
        }

        &--disabled {
            opacity: .75;
            pointer-events: none;
        }
    }

    .slide__text {
        position: absolute;
        inset: 0;
        left: 5vw;
        right: 5vw;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    @each $name, $breakpoint in $grid-breakpoints {
        &.swiper--destroy-#{$name} {
            @include media-breakpoint-up($name) {
                margin: 0 -20px;

                .swiper__slide {
                    margin: 0 !important;
                    padding: 20px;
                    width: 25% !important;

                    &.swiper-slide-duplicate {
                        display: none;
                    }
                }

                .swiper-wrapper {
                    transform: none !important;
                    flex-wrap: wrap;
                    height: unset !important;
                }
            }
        }
    }

    &--image_text {
        .slide__text {
            position: relative;
            left: 0;
            right: 0;
            padding: 5vw;

            @include media-breakpoint-up(lg) {
                position: absolute;
                left: 5vw;
                right: 5vw;
                padding: 0;
            }
        }

        .swiper__slide {
            .img-fluid {
                @include media-breakpoint-down(lg) {
                    position: absolute;
                    inset: 0;
                    height: 100%;
                    object-fit: cover;
                    min-height: 70vh;
                }
            }
        }
    }

    &--review {
        .swiper__slide {
            height: auto !important;
        }
    }
}

.swiper--visible {
    overflow: visible !important;
}

.swiper-controls {
    display: flex;
    height: 100%;
    justify-content: flex-end;
    align-items: flex-end;
    gap: spacer(4);

    .swiper__button {
        position: relative;
        top: unset;
        right: unset;
        bottom: unset;
        left: unset;
        transform: unset;
    }
}

.swiper--gallery  {
    &.swiper-horizontal {
        img {
            width: 100%;
            max-height: 40vh;
            object-fit: cover;
            height: 100%;
        }
    }
}

.swiper--gallery-thumbs {
    height: 100% !important;

    &:not(.swiper-initialized){
        display: none;
    }

    img, picture {
        height: 100%;
        object-fit: cover;
        cursor: pointer;
        width: 100%;
    }


    &[data-swiper-direction="horizontal"]{
        img, picture {
            max-height: 200px;
        }
        
        .swiper__slide {
            height: 100% !important;
        }
    }

    @include media-breakpoint-down(lg){
        display: none !important;
    }
}

.swiper--gallery, .swiper--gallery-thumbs {
    .swiper__slide  {
        overflow: hidden;
        border-radius: $border-radius;
        height: auto;
    }

    &:not(.swiper-initialized){
        overflow: visible;

        &[data-swiper-direction="horizontal"]{
            .swiper-wrapper {
                display: grid;
                grid-template-columns: 70% 30%; /* 70% for main content and 30% for images */
                grid-template-rows: 20vh 20vh 20vh;
                gap: spacer(6);
            }

            .swiper__slide {
                &:first-child {
                    position: relative;
                    grid-row: 1 / span 3;

                    &::after {
                        content: "";
                        position: absolute;
                        width: 150%;
                        background-color: $white;
                        height: 1px;
                        left: 50%;
                        transform: rotate(30deg) translateX(-50%);
                        transform-origin: center;
                        bottom: -50%;
                    }

                    & > * {
                        height: 100%;
                        object-fit: cover;
                        width: 100% !important;
                    }
                }

                &:nth-child(n+5){
                    display: none;
                }
            }
        }

        &[data-swiper-direction="vertical"]{
            .swiper-wrapper {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                grid-template-rows: 60vh 20vh;
                gap: spacer(6);
            }

            .swiper__slide {
                &:first-child {
                    grid-column: span 3;

                    & > * {
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                    }

                    &:before {
                        content: '';
                        position: absolute;
                        width: 150%;
                        height: 100%;
                        bottom: 0;
                        left: 0;
                        background: url('../../img/post-decoration-2.svg');
                        background-repeat: no-repeat;
                    }
                }

                &:nth-child(n+5){
                    display: none;
                }
            }
        }

        .swiper-lazy-preloader {
            display: none;
        }
    }
}


.swiper--steps {
    .swiper__slide {
        .step__number {
            &:after {
                content: "";
                position: absolute;
                left: 0;
                width: calc(100% + #{spacer(11)});
                transform: translateX(50%);
                height: 1px;
                background-color: rgba($primary, 6%);
            }
        }
        
        &:last-child {
            .step__number {
                &:after {
                    display: none;
                }
            }
        }
    }
}

[data-swiper-height="false"]{
    .swiper__slide {
        height: 400px;

        @include media-breakpoint-up(lg){
            min-height: 600px !important;
            max-height: 60vh !important;
        }

        & > * {
            object-fit: cover;
            object-position: center;
            height: 100%;
        }
    }
}

/* stylelint-enable */
;@import "sass-embedded-legacy-load-done:958";
// Card component


.card {
    display: flex;
    flex-direction: column;
    flex: 1;
    position: relative;
    background: $white;
    min-height: 100%;
    transition: all 350ms ease-in;
    width: 100%;
    border: 1px solid rgba($color-base, 0.08);
    border-radius: spacer(1) spacer(1) spacer(1) spacer(6);
    overflow: hidden;
    margin-top: 0;
    padding: 0;
    min-width: unset;
    max-width: unset;
}

.card__container {
    display: flex;
    flex-direction: column;
    background-color: $white;
    width: 100%;
}

.card__tags {
    position: absolute;
    padding: spacer(7);
    top: 0;
    display: flex;
    flex-wrap: wrap;
    max-width: 60%;
    gap: spacer(3);
    z-index: 2;
}

.card__body {
    flex: 1;
    padding: spacer(8) spacer(8) spacer(7) spacer(8);
}

.card__header {
    position: relative;
}

.card__title {
    font-size: $h5-font-size;
}

.card__image {
    height: 250px;
    width: 100%;
    object-fit: cover;
    max-width: unset;
}

.card__decoration {
    position: absolute;
    inset: 0;
    z-index: 1;

    &--line {
        &:after {
            content: "";
            width: 2px;
            background-color: $white;
            height: 100%;
            position: absolute;
            display: block;
            right: spacer(3);
            transform: rotate(17deg);
            transform-origin: top;
        }
    }

    &--square {
        &:after {
            content: "";
            top: 0;
            width: 100%;
            height: 30%;
            border-bottom: 1px solid $white;
            position: absolute;
            display: block;
        }
    }
}

.card__content {
    overflow-wrap: break-word;
    flex: 1;
}

.card__footer {
    padding: 0 spacer(8) spacer(8) spacer(8);
}

// Bade in cart
.card__badge {
    position: absolute;
    top: spacer(2);
    left: spacer(2);
    margin: 0;
    max-width: calc(100% - #{$spacer});
    overflow: hidden;
    text-overflow: ellipsis;
}

// Bordered card
.card--bordered {
    border: $card-border-width solid $card-border-color;

    .card-body,
    .card-footer {
        padding: $card-spacer-y;
    }
}

// Horizontal card
.card-horizontal-img {
    flex: 0 0 auto;
    width: auto;
}

// Responsive horizontal cards
@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

        .card#{$infix}-horizontal {
            flex-direction: row;
        }
    }
}

.card--review {
    border-radius: spacer(1) spacer(1) spacer(1) spacer(6);
    box-shadow: unset;
    border: unset;

    &:hover {
        transform: unset;
        box-shadow: unset;
    }

    .card__body {
        padding: spacer(10) spacer(8);
    }

    .card__title {
        font-size: $h5-font-size;
        font-family: $headings-font-family;
        font-weight: $headings-font-weight;
        line-height: 1.2;
    }

    .review {
        display: flex;
        align-items: center;
        margin-bottom: spacer(7);
    }

    .review__rating {
        font-family: $headings-font-family;
        font-weight: $headings-font-weight;
        
        &:after {
            position: relative;
            content: '—';
            font-size: px-to-rem(18);
            font-weight: 300;
            margin: 0 spacer(2);
        }
    }

}

.review__stars {
    --percent: calc(var(--rating) / 5 * 100%);
    
    font-size: px-to-rem(14);
    display: inline-block;
    background: linear-gradient(90deg, #EAB430 var(--percent), #AE8A31 var(--percent));
    background-clip: text !important;
    -webkit-text-fill-color: transparent;
}

.step {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: spacer(5);

    .step__number {
        border-radius: $border-radius-pill;
        display: flex;
        justify-content: center;
        align-items: center;
        width: spacer(11);
        aspect-ratio: 1/1;
        background-color: $primary;
        color: $white;
    }
    
    .step__text {
        text-align: center;
        padding: 0 spacer(4);
        font-family: $headings-font-family;
    }
}

.card--product {
    padding: spacer(8);

    .card__header {
        margin-bottom: spacer(12);
        
        .card__image {
            height: 200px;
        }
    }

    .card__body {
        padding: 0;

        ul {
            margin-bottom: 0;

            @extend .list-checked;
        }
    }

    .card__title {
        font-size: $h5-font-size;
        font-family: $headings-font-family;
        display: flex;
        flex-direction: column;
        margin-bottom: spacer(2);

        .brand {
            font-weight: $font-weight-semi-bold
        }
        
        .title {
            font-weight: $font-weight-light;
        }
    }

    .card__labels {
        display: flex;
        align-items: center;
        gap: spacer(5);
        margin-bottom: spacer(6);
    }
}

.energy-label {
    color: $white;
    mask-image: url('../../img/label-mask.png');
    mask-size: contain;
    mask-position: center center;
    mask-repeat: no-repeat;
    padding: 0 spacer(2);
    width: spacer(15);
    font-family: $headings-font-family;
    font-weight: $font-weight-medium;
        
    &--a, &--a-single, &--a-double, &--a-triple {
        background-color: #399439;
    }

    &--b {
        background-color: #49bf49;
    }

    &--c {
        background-color: #9ddf6c;
    }

    &--d {
        background-color: #ffd817;
    }

    &--e {
        background-color: #fdbd57;
    }

    &--f {
        background-color: #fd6b39;
    }
}


.card--project {
    display: flex;
    overflow: unset;
    min-height: 487px;
    margin-bottom: spacer(8);

    .card__header {
        .card__image {
            height: 100%;
            position: absolute;
        }

        position: unset;
        height: 100%;
        flex: 1;
        border-radius: $border-radius;
        overflow: hidden;

        &:before {
            content: '';
            position: absolute;
            inset: 0;
            background: linear-gradient(to bottom, rgba(#0A0A0A, 0.5), rgba(#F2F2F2, 0%));
            mix-blend-mode: overlay;
            height: 38%;
            backdrop-filter: blur(2px);
            z-index: 1;
            opacity: 0.9;
        }

        &:after {
            content: '';
            position: absolute;
            inset: 0;
            background: rgba(21, 55, 85, 32%);
            opacity: 0.3;
            backdrop-filter: blur(2px);
        }
        
    }

    .card__footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        bottom: - spacer(12);
        z-index: 10;
        background: $white;
        border-radius: $border-radius;
        padding: spacer(8);
        margin: spacer(4);
    }

    &-list {
        display: flex;
        flex-direction: row;
        min-height: unset;
        
        .card__header {
            position: relative;
            flex: unset;
            height: unset;

            .card__image {
                position: relative;
                width: 280px;
                height: 100%;
                object-fit: cover;
            }

            .card__tags {
                position: absolute;
                max-width: unset;
                padding: spacer(5);
                top: unset;
                bottom: 0;
            }
        }
        
        .card__footer {
            flex: 1;
            bottom: unset;
        }
    }
}

.card--article {
    .btn {
        font-size: px-to-rem(15);
        font-weight: $font-weight-medium;
        padding-left: spacer(7);
        padding-right: spacer(7);
        position: unset;
    }

    .card__content {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
    }
    
}

.card--location {
    flex-direction: row;
    
    @include media-breakpoint-up(lg) {
        flex-direction: column;
        text-align: center; 
    }

    .card__header {
        display: none;
        
        @include media-breakpoint-up(lg) {
            display: flex;
        }

        .card__image {
            height: 128px;
        }
    }

    .card__body {
        padding: spacer(10) spacer(8);
    }

    .card__title {
        font-family: $headings-font-family;
        font-weight: $font-weight-semi-bold;
    }

    .location__address, .location__zipcode {
        margin-bottom: 0;
    }

    .card__footer {
        display: flex;
        flex-direction: column;
        gap: spacer(3);
        padding: spacer(6) spacer(8);

        @include media-breakpoint-up(lg) {
            padding: spacer(0) spacer(8) spacer(8) spacer(8);
        }

        .btn {
            span {
                &:nth-child(2) {
                    display: none;

                    @include media-breakpoint-up(lg) {
                        display: flex;
                    }
                }
            }
        }
    }
}

;@import "sass-embedded-legacy-load-done:954";
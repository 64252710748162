input[type="checkbox"] {
    @extend .form-check-input;

    width: spacer(4) !important;
    height: spacer(4) !important;
    padding: spacer(3) !important;
    margin: 0;
    background-color: $quaternary;

    &:checked {
        color: $secondary;
        background-color: $white !important;
        background-image: url('../../img/icons/checkbox.svg');
        background-size: 12px;
        background-repeat: no-repeat;
        background-position: center;
    }
}

.gfield_checkbox {
    display: flex;
    flex-direction: column;
    gap: spacer(3);
}

;@import "sass-embedded-legacy-load-done:983";
// Navs for carousel

.carousel__buttons {
    width: 100%;
    margin: $container-padding-x;
}

.carousel__nav {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
}

.btn--carousel {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    width: spacer(14);
    height: spacer(14);
    transform-origin: center;
    cursor: pointer;
}

.btn-previous {
    transform: scaleX(-1);
}
;@import "sass-embedded-legacy-load-done:971";
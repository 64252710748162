.swiper--posts {
    padding: spacer(6); // Add if you use box-shadow
    padding-right: spacer(4);
    padding-left: spacer(4);

    .swiper-wrapper {
        align-items: stretch;
    }

    .swiper__slide {
        height: auto !important;

        &:nth-child(3n+1) {
            .card__header {
                &:before {
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    bottom: 0;
                    left: 0;
                    background: url('../../img/post-decoration-1.svg');  
                }
            }

        }

        &:nth-child(3n+2) {
            .card__header {
                &:before {
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    bottom: 0;
                    left: 0;
                    background: url('../../img/post-decoration-2.svg');
                }
               
            }
        }

        &:nth-child(3n+3) {
            .card__header {
                &:before {
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    bottom: 0;
                    left: 0;
                    background: url('../../img/post-decoration-3.svg');
                }
            }
        }
    }
}

;@import "sass-embedded-legacy-load-done:961";
$pe-popup-vars: (
    "pe-popup-title-fs": px-to-rem(18),
    "pe-popup-body-padding": spacer(2) 0 0 0,
    "btn-background": $tertiary,
    "pe-popup-margin": spacer(10) spacer(10) 0 spacer(10),
    "pe-popup-padding-y": spacer(3)
);

@include set-popup-variables($pe-popup-vars);

.pe-popup {  
  .popup__title {
    color: $secondary;
    font-weight: $font-weight-semi-bold;
  }
}

.pe-popup .btn {
  width: 100%;
  display: flex;
}
;@import "sass-embedded-legacy-load-done:986";